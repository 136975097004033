import { BiPlus, BiUserPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  Image,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'

import { GetCampaignDto, GetSubmissionDto } from '~shared/dtos'

import DocumentsFlying from '~/assets/DocumentsFlying.svg'

import { BasicAdminPage } from '../BasicAdminPage'
import { ManageCollaboratorAccessModal } from '../components/modals/ManageCollaboratorAccessModal'
import { CreateSubmissionModal } from '../Submissions/CreateSubmissionModal'

export interface EmptyCollectionProps {
  campaign: GetCampaignDto
  submissions: GetSubmissionDto[] | undefined
  onCreate: (submission: GetSubmissionDto) => void
}

export const EmptyCollection = ({
  campaign,
  submissions,
  onCreate,
}: EmptyCollectionProps): JSX.Element => {
  const createSubmissionDisclosure = useDisclosure()
  const navigate = useNavigate()
  const {
    isOpen: isOpenCollaboratorAccessModal,
    onOpen: onOpenCollaboratorAccessModal,
    onClose: onCloseCollaboratorAccessModal,
  } = useDisclosure()
  return (
    <BasicAdminPage
      title={campaign.name}
      buttons={
        <ButtonGroup>
          <Button
            leftIcon={<BiUserPlus fontSize="24px" />}
            variant={'outline'}
            onClick={onOpenCollaboratorAccessModal}
          >
            Manage access
          </Button>
          <Button
            leftIcon={<BiPlus fontSize="20px" />}
            onClick={() =>
              navigate(`/admin/collections/${campaign.id}/create-submission`)
            }
          >
            Create submission link
          </Button>
        </ButtonGroup>
      }
      backButtonProps={{
        label: 'Back to all collections',
        onClick: () => navigate(`/admin/collections/`),
      }}
    >
      <VStack spacing="20px" justify={'center'} height="55vh">
        <Image src={DocumentsFlying} mb="24px" />
        <VStack spacing="12px">
          <Text textStyle="h5" color="brand.primary.500">
            You don’t have any submission links yet
          </Text>
          <Text textStyle="h6" color="base.content.default">
            Create submission links to collect files
          </Text>
        </VStack>
        {submissions && (
          <CreateSubmissionModal
            {...createSubmissionDisclosure}
            campaign={campaign}
            submissions={submissions}
            onCreate={onCreate}
          />
        )}
        <ManageCollaboratorAccessModal
          isOpen={isOpenCollaboratorAccessModal}
          onClose={onCloseCollaboratorAccessModal}
          campaignName={campaign.name}
          campaignId={campaign.id}
          campaignOwnerEmail={campaign.contactEmail}
        />
      </VStack>
    </BasicAdminPage>
  )
}
